import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDepotNotificationSettings, IEmployee } from '@depot/custom';
import { environment } from '@env';

import { of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {
  // private locations: IDepotLocation[];
  private notificationSettings: IDepotNotificationSettings[];
  constructor(private httpClient: HttpClient) { }

  public getUsers() {
    const url = environment.get_endpoint(`user`);

    return this.httpClient.get<IEmployee[]>(url, {});

  }



  // /**
  //  *  @deprecated Use the `getDepotNotificationSettings` function instead.
  //  */
  // public getDepotLocations() {
  //   if (this.locations) {
  //     return of(this.locations);
  //   }
  //   const url = environment.get_endpoint(`user/DepotLocations`);

  //   return this.httpClient.get<IDepotLocation[]>(url, {})
  //     .pipe(tap(x => this.locations = x));
  // }

  public getDepotNotificationSettings() {
    if (this.notificationSettings) {
      return of(this.notificationSettings);
    }
    const url = environment.get_endpoint(`user/DepotNotificationSettings`);

    return this.httpClient.get<IDepotNotificationSettings[]>(url, {})
      .pipe(tap(x => this.notificationSettings = x));
  }

  // public getDepotContacts(id: string) {
  //   const url = environment.get_endpoint(`user/DepotContacts/${id}`);

  //   return this.httpClient.get<IDepotContact>(url);
  // }

  // /**
  //  *  @deprecated Use the `saveDepotNotificationSettings` function instead.
  //  */
  // public saveDepotContacts(depotContact: IDepotContact) {
  //   const url = environment.get_endpoint(`user/DepotContacts`);

  //   return this.httpClient.post<IDepotContact>(url, depotContact);
  // }

  public saveDepotNotificationSettings(depotUser: IEmployee) {
    const url = environment.get_endpoint(`user/DepotNotificationSettings`);

    return this.httpClient.post<IEmployee>(url, depotUser);
  }

  public getUser(id: string) {
    const url = environment.get_endpoint(`user/${id}`);

    return this.httpClient.get<IEmployee>(url);
  }


  public saveUser(saveData: IEmployee) {
    const url = environment.get_endpoint(`user/${saveData.id}`);

    return this.httpClient.put<IEmployee>(url, saveData);
  }

  public deleteUser(id: string) {
    const url = environment.get_endpoint(`user/${id}`);

    return this.httpClient.delete<void>(url);
  }

  // public deleteAuditRow(id: number) {
  //   const url = environment.get_endpoint(`audit/${id}`);

  //   return this.httpClient.delete(url);
  // }

}
