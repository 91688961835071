import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService, NeverCancelHeader, SettingsService } from '@depot/@common';
import { INotificationLogSearch, NotificationMessageFormat } from '@depot/@data';
import { SignalRBase } from '@depot/@data/signalr.base';
import { INotification, IPagedResults } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, tap } from 'rxjs';

import { HubConnectionState } from '@microsoft/signalr';
import { sortBy } from 'underscore';

type MethodNames = 'newNotification' | 'dismissNotification';
interface INotificationHub extends signalR.HubConnection {
  on(methodName: MethodNames, newMethod: (args: INotification) => void): void;
  invoke<T = INotification>(methodName: MethodNames, args: INotification): Promise<T>;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationRepositoryService extends SignalRBase<INotificationHub> {
  public notifications$ = new BehaviorSubject<INotification[]>([]);
  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private settingsService: SettingsService
  ) {
    super(environment.get_endpoint('NotificationHub'));

    this.hubConnection.on('newNotification', message => { this.addNotification(message); });

    this.hubConnection.on('dismissNotification', message => this.removeNotification(message));

    this.authService.user$.subscribe(async (user) => {
      if (user) {
        await this.hubConnection.start();
        this.connected$.next(true);

      } else {
        await this.hubConnection.stop();
        this.connected$.next(false);
        this.notifications$.next([]);
      }
    });

  }

  /**
   * Add a notification to the local notification subject and call {showNotification} method
   */
  public addNotification(message: INotification) {
    const notifications: INotification[] = this.notifications$.value ?? [];

    this.notifications$.next([message, ...notifications]);
    this.showNotification(message);

  }

  /**
   * Remove a notification from the local object
   */
  public removeNotification(message: INotification) {
    const notifications = this.notifications$.value ?? [];

    this.notifications$.next(notifications.filter(x => x.id !== message.id));

  }

  /**
   * Gets the notifications for the users groups or specific to them and applies it the the
   * notifications$ subject
   */
  public getRelevantNotifications() {
    const url = environment.get_endpoint('notification/me');
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start();
    }
    return this.httpClient.get<INotification[]>(url, { headers: NeverCancelHeader })
      .pipe(tap(messages => {
        this.notifications$.next(sortBy(messages, x => x.createdDate).reverse());

      }));
  }

  /**
   * Save a notification to the database
   */
  public saveNotification(value: INotification) {
    const url = environment.get_endpoint(`notification`);

    return this.httpClient.post<INotification>(url, value, { headers: NeverCancelHeader });

  }

  /**
   * Save a notification to the database
   */
  public getNotifications(filters: INotificationLogSearch) {
    const url = environment.get_endpoint(`notification`);

    return this.httpClient.get<IPagedResults<INotification>>(url, { params: <any>filters });

  }

  /**
   * Dismiss a notification be either just removing it locally or calling the signalR dismiss
   * action to affect all users
   */
  public async dismissNotification(message: INotification) {
    if (message.id > 0) {
      await this.hubConnection.invoke('dismissNotification', message);
    } else {
      this.removeNotification(message);
    }
    // remove the notification from the side panel
    const ns = await this.showNotification(message, true);
    ns?.close();

    setTimeout(() => {
      ns?.close();
    }, 300);
  }



  /**
   * Create an html notification if the settings are setup
   */
  public async showNotification(message: INotification, bypassSettings = false) {
    const settings = await this.settingsService.notification();
    let messageBody = 'New notification';
    if (message.messageFormat === NotificationMessageFormat.O365Email) {
      messageBody = 'New email received';
    } else if (message.messageFormat === NotificationMessageFormat.String) {
      messageBody = message.messageData;
    }
    if (settings.showNotification || bypassSettings) {
      return new Notification('Internal Depot Parts', {
        icon: '/app/assets/icon-96x96.png',
        body: messageBody,
        silent: !settings.playSound,
        // timestamp: message.createdDate.valueOf(),
        tag: message.id.toString(),
        data: message,
      });

    }

  }
}
