import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { IEmailNotificationModel, INotification } from '@depot/custom';

@Component({
  selector: 'depot-notification-email',
  template: `
  @if(emailData.orderId){
    <div>
    New email from {{emailData.from}} on order {{emailData.orderId}}
    <br />
    <button mat-button (click)="navigate()">Order Details</button>
  </div>
  }@else {
    <div>
      A new email was received but the Order Id could not be found
    </div>
  }
  `,
  styles: []
})
export class NotificationEmailComponent implements OnInit {

  @Input() notification: INotification;
  @Input() notificationDrawer: MatDrawer | null;
  public emailData: IEmailNotificationModel;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.emailData = <IEmailNotificationModel>JSON.parse(this.notification.messageData);
  }


  public navigate() {
    this.notificationDrawer?.close();
    this.router.navigate(['order', this.emailData.orderId, 'grid']);
  }
}
