import { Component, Input, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { IDealerReturnNotification, INotification } from '@depot/custom';

@Component({
  selector: 'depot-notification-dealer-return',
  template: `
  <div>
    New dealer return initiated from {{dealerReturnData.dealerNumber}} by {{dealerReturnData.submittedBy}}
    with id {{dealerReturnData.dealerReturnId}}
    <br />
    <button mat-button (click)="navigate()">Dealer Return Details</button>
  </div>
  `,
  styles: []
})
export class NotificationDealerReturnComponent implements OnInit {

  @Input() notification: INotification;
  @Input() notificationDrawer: MatDrawer;
  public dealerReturnData: IDealerReturnNotification;

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.dealerReturnData = <IDealerReturnNotification>JSON.parse(this.notification.messageData);
  }


  public navigate() {
    this.notificationDrawer?.close();
    this.router.navigate(['returns', 'pickup', this.dealerReturnData.dealerReturnId]);
  }
}
