import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env';

import { of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericInfoRepositoryService {

  private usStateResponse: Record<string, string>;
  private canadaProvincesResponse: Record<string, string>;
  private timeZones: { displayName: string; id: string }[];
  constructor(
    private httpClient: HttpClient
  ) { }

  public UsStates() {
    const url = environment.get_endpoint('genericinfo/UsStates');
    if (!this.usStateResponse) {
      this.httpClient.get<Record<string, string>>(url)
        .pipe(tap(x => this.usStateResponse = x));

    }
    return of(this.usStateResponse);
  }

  public CanadaProvinces() {
    if (!this.canadaProvincesResponse) {
      const url = environment.get_endpoint('genericinfo/CanadaProvinces');
      this.httpClient.get<Record<string, string>>(url)
        .pipe(tap(x => this.canadaProvincesResponse = x));
      ;
    }
    return of(this.canadaProvincesResponse);

  }

  public getTimezones() {
    if (this.timeZones) {
      return of(this.timeZones);
    }
    const url = environment.get_endpoint(`genericinfo/GetTimezones`);

    return this.httpClient.get<{ displayName: string; id: string }[]>(url, {})
      .pipe(tap(x => this.timeZones = x));


  }

}
